import React from "react";
import { useState, useEffect, useCallback } from "react";
import {
    Row, Col, Card, Table, Badge, ButtonGroup, Tooltip, OverlayTrigger, Button, Spinner
} from 'react-bootstrap';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ApexChart from "react-apexcharts";
import "react-datetime/css/react-datetime.css";
import {
    portalmetrics, releasemetrics, jobs, distributionmetrics, getdistributiondetails, getvncmetrics
    , getlov, getfotametrics, getsyncmetrics, getd2cmetrics, getusermetrics, gettelemetrymetrics
    , getsitemismatch, getvehiclespaired, getsite_mismatch_activity, getmodelseriesmetrics, getsiteswapslast24hrs
    , getinstallcompletelast24hrs, getvehiclekeyhourdata
} from "../service/getdata";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/index.css";
import "@inovua/reactdatagrid-community/theme/blue-light.css";
import "@inovua/reactdatagrid-community/theme/blue-dark.css";
import "@inovua/reactdatagrid-community/theme/amber-light.css";
import "@inovua/reactdatagrid-community/theme/amber-dark.css";
import "@inovua/reactdatagrid-community/theme/green-light.css";
import "@inovua/reactdatagrid-community/theme/green-dark.css";
import "@inovua/reactdatagrid-community/theme/pink-light.css";
const ExcelJS = require('exceljs');
const blobType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

const tooltip = (
    <Tooltip id="tooltip">
        This will slice Vehicle Details Grid Only
    </Tooltip>
);



const gridStyle = { minHeight: 390, fontSize: 10 }
const todaysDate = new Date(new Date().toUTCString()).toLocaleDateString()// + ",00:00:00 AM"
const rowStyle = ({ data }) => {

    // const colorMap = {
    //   ca: '#7986cb',
    //   uk: '#ef9a9a'
    // }

    return {
        fontSize: 10, // <-- or here
        //color: colorMap[data.country]
        // display: "inline-block",
        // "line-height": 1.5,
        // "overflow-wrap": "break-word",
        // "white-space": "normal",
        // "text-align": "justify",
    }
}
const defaultFilterValue =
    [
        { name: 'DealerName', type: 'string', operator: 'contains', value: '' },
        { name: 'BranchName', type: 'string', operator: 'contains', value: '' },
        { name: 'CompanyName', type: 'string', operator: 'contains', value: '' },
        { name: 'SiteName', type: 'string', operator: 'contains', value: '' },
        { name: 'SiteCode', type: 'string', operator: 'contains', value: '' },
        { name: 'VehicleIdentificationNumber', type: 'string', operator: 'contains', value: '' },
        { name: 'ModelNumber', type: 'string', operator: 'contains', value: '' }
    ]

const gridcols = [
    { header: "Dealer Name", name: 'DealerName', type: 'string', minWidth: 200, textAlign: 'center' },
    { header: "Branch Name", name: 'BranchName', type: 'string', minWidth: 200, headerAlign: 'center' },
    { header: "Company Name", name: 'CompanyName', type: 'string', minWidth: 200, headerAlign: 'center' },
    { header: "Site Name", name: 'SiteName', type: 'string', minWidth: 200, headerAlign: 'center' },
    { header: "Site Code", name: 'SiteCode', type: 'string', minWidth: 200, headerAlign: 'center' },
    { header: "VIN", name: 'VehicleIdentificationNumber', type: 'string', minWidth: 200, headerAlign: 'center' },
    { header: "Model", name: 'ModelNumber', type: 'string', minWidth: 200, headerAlign: 'center' },
    { header: "LastCommunication", name: 'LastCommunication', type: 'string', minWidth: 200, headerAlign: 'center' },
    { header: "TravelHours", name: 'TravelHours', type: 'string', minWidth: 200, headerAlign: 'center' },
    { header: "ActivityHours", name: 'ActivityHours', type: 'string', minWidth: 200, headerAlign: 'center' },
    { header: "KeyHours", name: 'KeyHours', type: 'string', minWidth: 200, headerAlign: 'center' },
    { header: "LiftHours", name: 'LiftHours', type: 'string', minWidth: 200, headerAlign: 'center' },
    { header: "BatteryVoltage", name: 'BatteryVoltage', type: 'string', minWidth: 200, headerAlign: 'center' },
    { header: "InstallDate", name: 'InstallDate', type: 'string', minWidth: 200, headerAlign: 'center' },
    { header: "SiteEventsURL", name: 'SiteEventsURL', type: 'string', minWidth: 200, headerAlign: 'center' },
]
const dealersearchgridStyle = { minHeight: 200, fontSize: 10 }

const dealersearchgrid = [
    { header: "Dealer Name", name: 'DealerName', type: 'string', minWidth: 200, textAlign: 'center' },
    { header: "Dealer Code", name: 'dealercode', type: 'string', minWidth: 200, textAlign: 'center' },
]

const dealersearchgriddefaultFilterValue =
    [
        { name: 'DealerName', type: 'string', operator: 'contains', value: '' },
        { name: 'dealercode', type: 'string', operator: 'contains', value: '' },
    ]

const branchsearchgrid = [
    { header: "Branch Name", name: 'BranchName', type: 'string', minWidth: 200, textAlign: 'center' },
    { header: "Branch Code", name: 'BranchCode', type: 'string', minWidth: 200, textAlign: 'center' },
]

const branchsearchgriddefaultFilterValue =
    [
        { name: 'BranchName', type: 'string', operator: 'contains', value: '' },
        { name: 'BranchCode', type: 'string', operator: 'contains', value: '' },
    ]


const companysearchgrid = [
    { header: "Company Name", name: 'CompanyName', type: 'string', minWidth: 200, textAlign: 'center' },
    { header: "Company Code", name: 'CompanyCode', type: 'string', minWidth: 200, textAlign: 'center' },
]

const companysearchgriddefaultFilterValue =
    [
        { name: 'CompanyName', type: 'string', operator: 'contains', value: '' },
        { name: 'CompanyCode', type: 'string', operator: 'contains', value: '' },
    ]

const sitesearchgrid = [
    { header: "Site Name", name: 'SiteName', type: 'string', minWidth: 200, textAlign: 'center' },
    { header: "Site Code", name: 'SiteCode', type: 'string', minWidth: 200, textAlign: 'center' },
]

const sitesearchgriddefaultFilterValue =
    [
        { name: 'SiteName', type: 'string', operator: 'contains', value: '' },
        { name: 'SiteCode', type: 'string', operator: 'contains', value: '' },
    ]


function DistributionSnapshot(props) {
    const [showsummary, setshowsummary] = useState(true);
    const [showdashboard, setshowdashboard] = useState(true);
    const [showvehicletelemetry, setshowvehicletelemetry] = useState(true);
    const [showfilter, setshowfilter] = useState(false);
    const [showvehicledetails, setshowvehicledetails] = useState(true);
    const [siteactivitybtn, setsiteactivitybtn] = useState(false);
    const [siteactivityfilter, setsiteactivityfilter] = useState(false);
    const [sitemismatchbtn, setsitemismatchbtn] = useState(false);
    const [sitemismatchfilter, setsitemismatchfilter] = useState(false);
    const [filterValue, setFilterValue] = useState(defaultFilterValue);
    const [customersitedata, setcustomersitedata] = useState(false);
    const [customersitefilter, setcustomersitefilter] = useState(false);
    const [distributiondata, setdistributiondata] = useState([]);
    const [vncmetricsdata, setvncmetricsdata] = useState([]);
    const [fotametricsdata, setfotametricsdata] = useState([]);
    const [syncmetricsdata, setsyncmetricsdata] = useState([]);
    const [modelseriesmetricsdata, setmodelseriesmetricsdata] = useState([]);
    const [d2cmetricsdata, setd2cmetricsdata] = useState([]);
    const [usermetricsdata, setusermetricsdata] = useState([]);
    const [telemetrymetricsdata, settelemetrymetricsdata] = useState([]);
    const [telemetrymetricsdataC2D, settelemetrymetricsdataC2D] = useState([]);
    const [sitemismatchdata, setsitemismatchdata] = useState([]);
    const [vehiclespaireddata, setvehiclespaireddata] = useState([]);
    const [mismatch_activity, setmismatch_activity] = useState([]);
    const [portal, setportal] = useState([]);
    const [release, setrelease] = useState([]);
    const [jobsdata, setjobsdata] = useState([]);
    const [exportcompleted, setexportcompleted] = useState(false)
    const [siteswapdata, setsiteswapdata] = useState([])
    const [installcompletedata, setinstallcompletedata] = useState([])
    const [vehiclekeyhourdata, setvehiclekeyhourdata] = useState([])

    const [selecteddealer, setselecteddealer] = useState();
    const [selectedbranch, setselectedbranch] = useState();
    const [selectedcompany, setselectedcompany] = useState();
    const [selectedsite, setselectedsite] = useState();
    const [selectedvnc, setselectedvnc] = useState();


    const [dealergridRef, setdealerGridRef] = useState(null)
    const [branchgridRef, setbranchGridRef] = useState(null)
    const [companygridRef, setcompanyGridRef] = useState(null)
    const [sitegridRef, setsiteGridRef] = useState(null)

    const downloadBlob = async () => {
        setexportcompleted(true)
        const pagesize = 500;
        let filterValue=[];
        var filter = []
        if (selecteddealer !== "" && selecteddealer !== undefined) {
            filter.push({ "customfilter": "selecteddealer", "name": "DealerId", "type": "string", "operator": "eq", "value": selecteddealer })
        }
        else
            filterValue = filterValue?.filter((item) => item.customfilter !== "selecteddealer")
        if (selectedbranch !== "" && selectedbranch !== undefined) {
            filter.push({ "customfilter": "selectedbranch", "name": "BranchId", "type": "string", "operator": "eq", "value": selectedbranch })
        }
        else
            filterValue = filterValue?.filter((item) => item.customfilter !== "selectedbranch")
        if (selectedcompany !== "" && selectedcompany !== undefined) {
            filter.push({ "customfilter": "selectedcompany", "name": "CompanyId", "type": "string", "operator": "eq", "value": selectedcompany })
        }
        else
            filterValue = filterValue?.filter((item) => item.customfilter !== "selectedcompany")
        if (selectedsite !== "" && selectedsite !== undefined) {
            filter.push({ "customfilter": "selectedsite", "name": "SiteId", "type": "string", "operator": "eq", "value": selectedsite })
        }
        else
            filterValue = filterValue?.filter((item) => item.customfilter !== "selectedsite")
        if (siteactivitybtn)
            filter.push({ "customfilter": "siteactivitybtn", "name": "SiteActivity", "type": "int", "operator": "eq", "value": "1" })
        else
            filterValue = filterValue?.filter((item) => item.customfilter !== "siteactivitybtn")
        if (sitemismatchbtn)
            filter.push({ "customfilter": "sitemismatchbtn", "name": "SiteMismatch", "type": "int", "operator": "eq", "value": "1" })
        else
            filterValue = filterValue?.filter((item) => item.customfilter !== "sitemismatchbtn")

        if (customersitedata) {
            filter.push({ "customfilter": "customersitedata", "name": "SiteId", "type": "int", "operator": "neq", "value": "3" })
            filter.push({ "customfilter": "customersitedata", "name": "SiteId", "type": "int", "operator": "neq", "value": "4" })
        }
        else {
            filterValue = filterValue?.filter((item) => item.customfilter !== "customersitedata")
        }
        filter.forEach(element => {
            filterValue.push(element)
        });
        filterValue = JSON.parse(JSON.stringify(filterValue))
        var filterValuestr = Array.from(new Set(filterValue.map((item) => JSON.stringify(item))))
        filterValue = filterValuestr.map((item) => { if (item !== undefined) { return JSON.parse(item) } })
        var firstrecordset = await getdistributiondetails(0, pagesize, null, null, filterValue)
        var downloadset = JSON.parse(JSON.stringify(firstrecordset.data));
        var recordcount = firstrecordset.count;
        var numpages = Math.ceil(recordcount / pagesize) - 1;
        var skip = pagesize
        for (var i = 0; i < numpages; i++) {
            var interim_recordset = await getdistributiondetails(skip, pagesize, null, null, filterValue)
            interim_recordset?.data?.forEach((item) => {
                downloadset.push(item)
            })
            skip += pagesize;
        }
        if (downloadset?.length > 0) {
            const workbook = new ExcelJS.Workbook();
            const ws = workbook.addWorksheet('My Sheet');
            var columns = []
            Object.keys(downloadset[0]).forEach((item) => {
                columns.push({ header: item, key: item, width: 15 })
            })
            ws.columns = columns;
            ws.addRows(downloadset);
            workbook.xlsx.writeBuffer().then(data => {
                const blob = new Blob([data], { type: blobType });
                const link = document.createElement('a');
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', 'vehicledetails.xlsx');
                link.style.position = 'absolute';
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setexportcompleted(false);
            })
        }
    };

    const onDealerFilterValueChange = (data) => {
        var clearfilter = false
        clearfilter = data.every(element => element.value === "" ? true : false);
        if (clearfilter) {
            branchgridRef?.current?.clearAllFilters()
            companygridRef?.current?.clearAllFilters()
            sitegridRef?.current?.clearAllFilters()
            setselecteddealer(undefined)
        }
    }

    const onSelectionChange = useCallback(({ selected, data, unselected }) => {
        //console.log("data")
        //console.log(data)
        setselecteddealer(data.DealerId)
    }, [])

    const onSelectionChangeBranch = useCallback(({ selected, data, unselected }) => {
        setselectedbranch(data.BranchId)
    }, [])

    const onSelectionChangeCompany = useCallback(({ selected, data, unselected }) => {
        setselectedcompany(data.CompanyId)
    }, [])

    const onSelectionChangeSite = useCallback(({ selected, data, unselected }) => {
        setselectedsite(data.SiteId)
    }, [])

    const onVncSelectionChange = (data) => {        
       setselectedvnc(data)
    }

    const filterclick = () => {
        setshowfilter(!showfilter)
        setdealerGridRef(null)
        setbranchGridRef(null)
        setcompanyGridRef(null)
        setsiteGridRef(null)
    }

    const vehicledetailsclicked = () => {
        setshowvehicledetails(!showvehicledetails)
    }

    const vehicletelemetryclicked = () => {
        setshowvehicletelemetry(!showvehicletelemetry)
    }
    const summaryclicked = () => {
        setshowsummary(!showsummary)
    }
    const dashboardclicked = () => {
        setshowdashboard(!showdashboard)
    }
    const sitemismatchclicked = () => {
        setsitemismatchbtn(!sitemismatchbtn)
        setsitemismatchfilter(!sitemismatchfilter)
    }
    const siteactivityclicked = () => {
        setsiteactivitybtn(!siteactivitybtn)
        setsiteactivityfilter(!siteactivityfilter)
    }

    const clearall = () => {
        dealergridRef?.current?.clearAllFilters()
        branchgridRef?.current?.clearAllFilters()
        companygridRef?.current?.clearAllFilters()
        sitegridRef?.current?.clearAllFilters()
        setselecteddealer(undefined)
        setselectedbranch(undefined)
        setselectedcompany(undefined)
        setselectedsite(undefined)
    }


    const loadData = ({ skip, limit, sortInfo, groupBy, filterValue }) => {
        var filter = []
        if (selecteddealer !== "" && selecteddealer !== undefined) {
            filter.push({ "customfilter": "selecteddealer", "name": "DealerId", "type": "string", "operator": "eq", "value": selecteddealer })
        }
        else
            filterValue = filterValue.filter((item) => item.customfilter !== "selecteddealer")
        if (selectedbranch !== "" && selectedbranch !== undefined) {
            filter.push({ "customfilter": "selectedbranch", "name": "BranchId", "type": "string", "operator": "eq", "value": selectedbranch })
        }
        else
            filterValue = filterValue.filter((item) => item.customfilter !== "selectedbranch")
        if (selectedcompany !== "" && selectedcompany !== undefined) {
            filter.push({ "customfilter": "selectedcompany", "name": "CompanyId", "type": "string", "operator": "eq", "value": selectedcompany })
        }
        else
            filterValue = filterValue.filter((item) => item.customfilter !== "selectedcompany")
        if (selectedsite !== "" && selectedsite !== undefined) {
            filter.push({ "customfilter": "selectedsite", "name": "SiteId", "type": "string", "operator": "eq", "value": selectedsite })
        }
        else
            filterValue = filterValue.filter((item) => item.customfilter !== "selectedsite")
        if (siteactivitybtn)
            filter.push({ "customfilter": "siteactivitybtn", "name": "SiteActivity", "type": "int", "operator": "eq", "value": "1" })
        else
            filterValue = filterValue.filter((item) => item.customfilter !== "siteactivitybtn")
        if (sitemismatchbtn)
            filter.push({ "customfilter": "sitemismatchbtn", "name": "SiteMismatch", "type": "int", "operator": "eq", "value": "1" })
        else
            filterValue = filterValue.filter((item) => item.customfilter !== "sitemismatchbtn")

        if (customersitedata) {
            filter.push({ "customfilter": "customersitedata", "name": "SiteId", "type": "int", "operator": "neq", "value": "3" })
            filter.push({ "customfilter": "customersitedata", "name": "SiteId", "type": "int", "operator": "neq", "value": "4" })
        }
        else {
            filterValue = filterValue.filter((item) => item.customfilter !== "customersitedata")
        }
        filter.forEach(element => {
            filterValue.push(element)
        });
        filterValue = JSON.parse(JSON.stringify(filterValue))
        var filterValuestr = Array.from(new Set(filterValue.map((item) => JSON.stringify(item))))
        filterValue = filterValuestr.map((item) => { if (item !== undefined) { return JSON.parse(item) } })        
        return getdistributiondetails(skip, limit, sortInfo, groupBy, filterValue)
    }

    const loaddealerlov = ({ skip, limit, sortInfo, groupBy, filterValue }) => {
        branchgridRef?.current?.clearAllFilters()
        companygridRef?.current?.clearAllFilters()
        sitegridRef?.current?.clearAllFilters()
        return getlov(skip, limit, sortInfo, groupBy, filterValue, "dealer")
    }

    const loadbranchlov = ({ skip, limit, sortInfo, groupBy, filterValue }) => {
        companygridRef?.current?.clearAllFilters()
        sitegridRef?.current?.clearAllFilters()
        var filter = [];
        if (selecteddealer !== "" && selecteddealer !== undefined) {
            filter.push({ "name": "d.DealerId", "type": "string", "operator": "eq", "value": selecteddealer })
        }
        filter.forEach(element => {
            filterValue.push(element)
        });
        return getlov(skip, limit, sortInfo, groupBy, filterValue, "branch")
    }

    const loadsitelov = ({ skip, limit, sortInfo, groupBy, filterValue }) => {
        var filter = [];
        if (selecteddealer !== "" && selecteddealer !== undefined) {
            filter.push({ "name": "d.DealerId", "type": "string", "operator": "eq", "value": selecteddealer })
        }
        if (selectedbranch !== "" && selectedbranch !== undefined) {
            filter.push({ "name": "b.BranchId", "type": "string", "operator": "eq", "value": selectedbranch })
        }
        if (selectedcompany !== "" && selectedcompany !== undefined) {
            filter.push({ "name": "c.CompanyId", "type": "string", "operator": "eq", "value": selectedcompany })
        }
        filter.forEach(element => {
            filterValue.push(element)
        });
        return getlov(skip, limit, sortInfo, groupBy, filterValue, "site")
    }

    const loadcompanylov = ({ skip, limit, sortInfo, groupBy, filterValue }) => {
        sitegridRef?.current?.clearAllFilters()
        var filter = [];
        if (selecteddealer !== "" && selecteddealer !== undefined) {
            filter.push({ "name": "d.DealerId", "type": "string", "operator": "eq", "value": selecteddealer })
        }
        if (selectedbranch !== "" && selectedbranch !== undefined) {
            filter.push({ "name": "b.BranchId", "type": "string", "operator": "eq", "value": selectedbranch })
        }
        filter.forEach(element => {
            filterValue.push(element)
        });
        return getlov(skip, limit, sortInfo, groupBy, filterValue, "company")
    }

    const dataSource = useCallback(loadData, [customersitedata, siteactivitybtn, sitemismatchbtn, selecteddealer
        , selectedbranch, selectedcompany, selectedsite])
    const dataSourceDealer = useCallback(loaddealerlov, [])
    const dataSourceBranch = useCallback(loadbranchlov, [selecteddealer])
    const dataSourceSite = useCallback(loadsitelov, [selecteddealer, selectedbranch, selectedcompany])
    const dataSourceCompany = useCallback(loadcompanylov, [selecteddealer, selectedbranch])

    useEffect(() => {
        getsiteswapslast24hrs().then((d) => {
            setsiteswapdata(d?.data)
        })
    }, [siteswapdata.length])

    useEffect(() => {
        getinstallcompletelast24hrs().then((d) => {
            setinstallcompletedata(d?.data)
        })
    }, [installcompletedata.length])

    useEffect(() => {
        getsite_mismatch_activity().then((d) => {
            var datum = []
            datum["SiteMismatchCount"] = 0;
            datum["SiteActivityCount"] = 0;
            d?.data.map((item) => {
                if (item?.type === "SiteActivityCount") {
                    datum["SiteActivityCount"] = item?.Count
                }
                if (item?.type === "SiteMismatchCount") {
                    datum["SiteMismatchCount"] = item?.Count
                }
            })
            setmismatch_activity(datum)
        });
    }, [mismatch_activity.length])

    useEffect(() => {
        var filter = [];
        if (customersitedata) {
            filter.push({ "customfilter": "customersitedata", "name": "SiteId", "type": "int", "operator": "neq", "value": "3" })
            filter.push({ "customfilter": "customersitedata", "name": "SiteId", "type": "int", "operator": "neq", "value": "4" })
        }
        filter = JSON.parse(JSON.stringify(filter))
        var filterValuestr = Array.from(new Set(filter.map((item) => JSON.stringify(item))))
        filter = filterValuestr.map((item) => { if (item !== undefined) { return JSON.parse(item) } })

        getvehiclekeyhourdata({ filterValue: filter }).then((d) => {
            setvehiclekeyhourdata(JSON.parse(JSON.stringify(d?.data)))
        })
    }, [vehiclekeyhourdata.length, customersitedata])

    useEffect(() => {
        distributionmetrics().then((d) => {
            setdistributiondata(JSON.parse(JSON.stringify(d?.data)))
        });
    }, [distributiondata.length])

    useEffect(() => {
        var filter = [];
        if (selecteddealer !== "" && selecteddealer !== undefined) {
            filter.push({ "name": "DealerId", "type": "string", "operator": "eq", "value": selecteddealer })
        }
        if (selectedbranch !== "" && selectedbranch !== undefined) {
            filter.push({ "name": "BranchId", "type": "string", "operator": "eq", "value": selectedbranch })
        }
        if (selectedcompany !== "" && selectedcompany !== undefined) {
            filter.push({ "name": "CompanyId", "type": "string", "operator": "eq", "value": selectedcompany })
        }
        if (selectedsite !== "" && selectedsite !== undefined) {
            filter.push({ "name": "SiteId", "type": "string", "operator": "eq", "value": selectedsite })
        }
        // if (siteactivitybtn) {
        //     filter.push({ "name": "SiteActivity", "type": "string", "operator": "eq", "value": 1 })
        // }
        // if (sitemismatchbtn) {
        //     filter.push({ "name": "SiteMismatch", "type": "string", "operator": "eq", "value": 1 })
        // }
        if (customersitedata) {
            filter.push({ "customfilter": "customersitedata", "name": "SiteId", "type": "int", "operator": "neq", "value": "3" })
            filter.push({ "customfilter": "customersitedata", "name": "SiteId", "type": "int", "operator": "neq", "value": "4" })
        }
        getd2cmetrics({ filterValue: filter }).then((d) => {
            setd2cmetricsdata(JSON.parse(JSON.stringify(d?.data)))
            //console.log(d2cmetricsdata)
        });
    }, [d2cmetricsdata.length, selecteddealer, selectedbranch, selectedcompany, selectedsite
        , customersitedata])

    useEffect(() => {
        var filter = [];
        filter.push({ "name": "Direction", "type": "string", "operator": "eq", "value": "Inbound" })
        if (selecteddealer !== "" && selecteddealer !== undefined) {
            filter.push({ "name": "DealerId", "type": "string", "operator": "eq", "value": selecteddealer })
        }
        if (selectedbranch !== "" && selectedbranch !== undefined) {
            filter.push({ "name": "BranchId", "type": "string", "operator": "eq", "value": selectedbranch })
        }
        if (selectedcompany !== "" && selectedcompany !== undefined) {
            filter.push({ "name": "CompanyId", "type": "string", "operator": "eq", "value": selectedcompany })
        }
        if (selectedsite !== "" && selectedsite !== undefined) {
            filter.push({ "name": "SiteId", "type": "string", "operator": "eq", "value": selectedsite })
        }
        // if (siteactivitybtn) {
        //     filter.push({ "name": "SiteActivity", "type": "string", "operator": "eq", "value": 1 })
        // }
        // if (sitemismatchbtn) {
        //     filter.push({ "name": "SiteMismatch", "type": "string", "operator": "eq", "value": 1 })
        // }
        if (customersitedata) {
            filter.push({ "customfilter": "customersitedata", "name": "SiteId", "type": "int", "operator": "neq", "value": "3" })
            filter.push({ "customfilter": "customersitedata", "name": "SiteId", "type": "int", "operator": "neq", "value": "4" })
        }
        filter = JSON.parse(JSON.stringify(filter))
        var filterValuestr = Array.from(new Set(filter.map((item) => JSON.stringify(item))))
        filter = filterValuestr.map((item) => { if (item !== undefined) { return JSON.parse(item) } })

        gettelemetrymetrics({ filterValue: filter }).then((d) => {
            settelemetrymetricsdata(JSON.parse(JSON.stringify(d?.data)))
        });
    }, [telemetrymetricsdata.length, selecteddealer, selectedbranch, selectedcompany, selectedsite
        , customersitedata])

    useEffect(() => {
        var filter = [];
        filter.push({ "name": "Direction", "type": "string", "operator": "eq", "value": "Outbound" })

        if (selecteddealer !== "" && selecteddealer !== undefined) {
            filter.push({ "name": "DealerId", "type": "string", "operator": "eq", "value": selecteddealer })
        }

        if (selectedbranch !== "" && selectedbranch !== undefined) {
            filter.push({ "name": "BranchId", "type": "string", "operator": "eq", "value": selectedbranch })
        }
        if (selectedcompany !== "" && selectedcompany !== undefined) {
            filter.push({ "name": "CompanyId", "type": "string", "operator": "eq", "value": selectedcompany })
        }
        if (selectedsite !== "" && selectedsite !== undefined) {
            filter.push({ "name": "SiteId", "type": "string", "operator": "eq", "value": selectedsite })
        }
        // if (siteactivitybtn) {
        //     filter.push({ "name": "SiteActivity", "type": "string", "operator": "eq", "value": 1 })
        // }
        // if (sitemismatchbtn) {
        //     filter.push({ "name": "SiteMismatch", "type": "string", "operator": "eq", "value": 1 })
        // }
        if (customersitedata) {
            filter.push({ "customfilter": "customersitedata", "name": "SiteId", "type": "int", "operator": "neq", "value": "3" })
            filter.push({ "customfilter": "customersitedata", "name": "SiteId", "type": "int", "operator": "neq", "value": "4" })
        }
        filter = JSON.parse(JSON.stringify(filter))
        var filterValuestr = Array.from(new Set(filter.map((item) => JSON.stringify(item))))
        filter = filterValuestr.map((item) => { if (item !== undefined) { return JSON.parse(item) } })

        gettelemetrymetrics({ filterValue: filter }).then((d) => {
            settelemetrymetricsdataC2D(JSON.parse(JSON.stringify(d?.data)))
        });
    }, [telemetrymetricsdataC2D.length, selecteddealer, selectedbranch, selectedcompany, selectedsite
        , customersitedata])

    useEffect(() => {
        var filter = [];
        if (selecteddealer !== "" && selecteddealer !== undefined) {
            filter.push({ "name": "DealerId", "type": "string", "operator": "eq", "value": selecteddealer })
        }
        if (selectedbranch !== "" && selectedbranch !== undefined) {
            filter.push({ "name": "BranchId", "type": "string", "operator": "eq", "value": selectedbranch })
        }
        if (selectedcompany !== "" && selectedcompany !== undefined) {
            filter.push({ "name": "CompanyId", "type": "string", "operator": "eq", "value": selectedcompany })
        }
        if (selectedsite !== "" && selectedsite !== undefined) {
            filter.push({ "name": "SiteId", "type": "string", "operator": "eq", "value": selectedsite })
        }
        // if (siteactivitybtn) {
        //     filter.push({ "name": "SiteActivity", "type": "string", "operator": "eq", "value": 1 })
        // }
        // if (sitemismatchbtn) {
        //     filter.push({ "name": "SiteMismatch", "type": "string", "operator": "eq", "value": 1 })
        // }
        if (customersitedata) {
            filter.push({ "customfilter": "customersitedata", "name": "SiteId", "type": "int", "operator": "neq", "value": "3" })
            filter.push({ "customfilter": "customersitedata", "name": "SiteId", "type": "int", "operator": "neq", "value": "4" })
        }
        filter = JSON.parse(JSON.stringify(filter))
        var filterValuestr = Array.from(new Set(filter.map((item) => JSON.stringify(item))))
        filter = filterValuestr.map((item) => { if (item !== undefined) { return JSON.parse(item) } })

        getvncmetrics({ filterValue: filter }).then((d) => {
            setvncmetricsdata(JSON.parse(JSON.stringify(d?.data)))
            //vncmetricsd = vehiclemetricsdata["vncmetrics"]//JSON.parse(JSON.stringify(vehiclemetricsdata))["vncmetrics"]
            //fotametricsd = vehiclemetricsdata["fotametrics"]//JSON.parse(JSON.stringify(vehiclemetricsdata))["fotametrics"]
            //syncstatusmetricsd = vehiclemetricsdata["syncstatusmetrics"]//JSON.parse(JSON.stringify(vehiclemetricsdata))["syncstatusmetrics"]
        });
    }, [vncmetricsdata.length, selecteddealer, selectedbranch, selectedcompany, selectedsite, customersitedata])

    useEffect(() => {
        var filter = [];
        if (selecteddealer !== "" && selecteddealer !== undefined) {
            filter.push({ "name": "DealerId", "type": "string", "operator": "eq", "value": selecteddealer })
        }
        if (selectedbranch !== "" && selectedbranch !== undefined) {
            filter.push({ "name": "BranchId", "type": "string", "operator": "eq", "value": selectedbranch })
        }
        if (selectedcompany !== "" && selectedcompany !== undefined) {
            filter.push({ "name": "CompanyId", "type": "string", "operator": "eq", "value": selectedcompany })
        }
        if (selectedsite !== "" && selectedsite !== undefined) {
            filter.push({ "name": "SiteId", "type": "string", "operator": "eq", "value": selectedsite })
        }
        if (siteactivitybtn) {
            filter.push({ "name": "SiteActivity", "type": "string", "operator": "eq", "value": 1 })
        }
        // if (sitemismatchbtn) {
        //     filter.push({ "name": "SiteMismatch", "type": "string", "operator": "eq", "value": 1 })
        // }
        if (customersitedata) {
            filter.push({ "customfilter": "customersitedata", "name": "SiteId", "type": "int", "operator": "neq", "value": "3" })
            filter.push({ "customfilter": "customersitedata", "name": "SiteId", "type": "int", "operator": "neq", "value": "4" })
        }
        if (selectedvnc) {
            filter.push({ "name": "selectedvnc", "type": "string", "operator": "eq", "value": selectedvnc })
        }
        filter = JSON.parse(JSON.stringify(filter))
        var filterValuestr = Array.from(new Set(filter.map((item) => JSON.stringify(item))))
        filter = filterValuestr.map((item) => { if (item !== undefined) { return JSON.parse(item) } })

        getfotametrics({ filterValue: filter }).then((d) => {
            setfotametricsdata(JSON.parse(JSON.stringify(d?.data)))
        });
    }, [fotametricsdata.length, selecteddealer, selectedbranch, selectedcompany, selectedsite, customersitedata
        , selectedvnc])

    useEffect(() => {
        var filter = [];
        if (selecteddealer !== "" && selecteddealer !== undefined) {
            filter.push({ "name": "DealerId", "type": "string", "operator": "eq", "value": selecteddealer })
        }
        if (selectedbranch !== "" && selectedbranch !== undefined) {
            filter.push({ "name": "BranchId", "type": "string", "operator": "eq", "value": selectedbranch })
        }
        if (selectedcompany !== "" && selectedcompany !== undefined) {
            filter.push({ "name": "CompanyId", "type": "string", "operator": "eq", "value": selectedcompany })
        }
        if (selectedsite !== "" && selectedsite !== undefined) {
            filter.push({ "name": "SiteId", "type": "string", "operator": "eq", "value": selectedsite })
        }
        // if (siteactivitybtn) {
        //     filter.push({ "name": "SiteActivity", "type": "string", "operator": "eq", "value": 1 })
        // }
        // if (sitemismatchbtn) {
        //     filter.push({ "name": "SiteMismatch", "type": "string", "operator": "eq", "value": 1 })
        // }
        if (customersitedata) {
            filter.push({ "customfilter": "customersitedata", "name": "SiteId", "type": "int", "operator": "neq", "value": "3" })
            filter.push({ "customfilter": "customersitedata", "name": "SiteId", "type": "int", "operator": "neq", "value": "4" })
        }
        if (selectedvnc) {
            filter.push({ "name": "selectedvnc", "type": "string", "operator": "eq", "value": selectedvnc })
        }
        filter = JSON.parse(JSON.stringify(filter))
        var filterValuestr = Array.from(new Set(filter.map((item) => JSON.stringify(item))))
        filter = filterValuestr.map((item) => { if (item !== undefined) { return JSON.parse(item) } })
        //console.log(filter)
        getsyncmetrics({ filterValue: filter }).then((d) => {
            setsyncmetricsdata(JSON.parse(JSON.stringify(d?.data)))
        });
    }, [syncmetricsdata.length, selecteddealer, selectedbranch, selectedcompany, selectedsite, customersitedata
        , selectedvnc])

    useEffect(() => {
        var filter = [];
        if (selecteddealer !== "" && selecteddealer !== undefined) {
            filter.push({ "name": "DealerId", "type": "string", "operator": "eq", "value": selecteddealer })
        }
        if (selectedbranch !== "" && selectedbranch !== undefined) {
            filter.push({ "name": "BranchId", "type": "string", "operator": "eq", "value": selectedbranch })
        }
        if (selectedcompany !== "" && selectedcompany !== undefined) {
            filter.push({ "name": "CompanyId", "type": "string", "operator": "eq", "value": selectedcompany })
        }
        if (selectedsite !== "" && selectedsite !== undefined) {
            filter.push({ "name": "SiteId", "type": "string", "operator": "eq", "value": selectedsite })
        }
        if (siteactivitybtn) {
            filter.push({ "name": "SiteActivity", "type": "string", "operator": "eq", "value": 1 })
        }
        if (sitemismatchbtn) {
            filter.push({ "name": "SiteMismatch", "type": "string", "operator": "eq", "value": 1 })
        }
        if (customersitedata) {
            filter.push({ "customfilter": "customersitedata", "name": "SiteId", "type": "int", "operator": "neq", "value": "3" })
            filter.push({ "customfilter": "customersitedata", "name": "SiteId", "type": "int", "operator": "neq", "value": "4" })
        }
        if (selectedvnc) {
            filter.push({ "name": "selectedvnc", "type": "string", "operator": "eq", "value": selectedvnc })
        }
        filter = JSON.parse(JSON.stringify(filter))
        var filterValuestr = Array.from(new Set(filter.map((item) => JSON.stringify(item))))
        filter = filterValuestr.map((item) => { if (item !== undefined) { return JSON.parse(item) } })
        //console.log(filter)
        getmodelseriesmetrics({ filterValue: filter }).then((d) => {
            var datum = JSON.parse(JSON.stringify(d?.data));
            console.log(datum)
            // var series = [];
            // var data = [];
            // datum.map((item) => {
            //     series.push(item.Modelseries)
            //     data.push(item.recordcount)
            // })

            // modelseriescounts["series"] = series
            // modelseriescounts["data"] = data
            setmodelseriesmetricsdata(datum)
        });
    }, [modelseriesmetricsdata.length, selecteddealer, selectedbranch, selectedcompany, selectedsite, siteactivitybtn
        , sitemismatchbtn, customersitedata, selectedvnc])

    useEffect(() => {
        getusermetrics().then((d) => {
            var datum = []
            datum["COMPANY"] = { "PENDING": 0, "ACCEPTED": 0 };
            datum["BRANCH"] = { "PENDING": 0, "ACCEPTED": 0 };
            datum["SITE"] = { "PENDING": 0, "ACCEPTED": 0 };
            datum["DEALER"] = { "PENDING": 0, "ACCEPTED": 0 };
            datum["MANUFACTURER"] = { "PENDING": 0, "ACCEPTED": 0 };
            d?.data.map((item) => {
                datum[item.EntityType][item.InvitationStatus] = item.UserCount
            })
            //console.log(datum)
            setusermetricsdata(datum)
        });
    }, [usermetricsdata.length])

    useEffect(() => {
        var filter = [];
        if (customersitedata) {
            filter.push({ "customfilter": "customersitedata", "name": "SiteId", "type": "int", "operator": "neq", "value": "3" })
            filter.push({ "customfilter": "customersitedata", "name": "SiteId", "type": "int", "operator": "neq", "value": "4" })
        }
        getsitemismatch({ filterValue: filter }).then((d) => {
            var datum = []
            datum["MatchCount"] = 0;
            datum["MismatchCount"] = 0;
            datum["Unknown"] = 0;
            d?.data.map((item) => {
                datum[item.SiteMismatch] = item.MismatchCount
            })
            setsitemismatchdata(datum)
        });
    }, [sitemismatchdata.length, customersitedata])

    useEffect(() => {
        getvehiclespaired().then((d) => {
            var datum = []
            datum["RECENTLYPAIRED"] = 0;
            datum["NA"] = 0;
            d?.data.map((item) => {
                datum[item.PairingUpdate] = item.Count
            })
            setvehiclespaireddata(datum)
        });
    }, [vehiclespaireddata.length])

    useEffect(() => {
        jobs().then((d) => {
            //console.log(db)
            setjobsdata(JSON.parse(JSON.stringify(d?.data)))

        });
    }, [jobsdata.length])

    useEffect(() => {
        portalmetrics().then((d) => {
            //console.log(iot)
            setportal(d?.data)

        });
    }, [portal.length])

    useEffect(() => {
        releasemetrics().then((d) => {
            //console.log(iot)
            setrelease(d?.data)
        });
    }, [release.length])

    const customersites = () => {
        setcustomersitedata(!customersitedata)
        setcustomersitefilter(!customersitefilter)
    }

    var vncbarchart = {
        series: [{
            data: vncmetricsdata.map(function (metrics) { return metrics.VehicleCount; })//[1,2,3]//
        }],
        options: {
            labels: ["1", "2", "3"],
            chart: {
                type: 'bar',
                height: 150,
                width: 300,
                events: {
                    dataPointSelection: (event, chartContext, config) => {                         
                        //console.log(chartContext);                      
                        if(chartContext.w.globals.selectedDataPoints[0].length==1){
                            //console.log(chartContext.w.config.labels[config.dataPointIndex]);
                            onVncSelectionChange(chartContext.w.config.labels[config.dataPointIndex]);
                        } else{
                            //console.log("0");
                            onVncSelectionChange(0);
                        }
                        
                        //console.log(chartContext.w.config.labels[config.dataPointIndex]);                
                    },
                    
                }
            },
            states: {
                normal: {
                    filter: {
                        type: 'lighten',
                        value: 0.1,
                    }
                },
                hover: {
                    filter: {
                        type: 'lighten',
                        value: 0.25,
                    }
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: 'darken',
                        value: 1,
                    }
                },                
            },
            legend: {
                show: false
            },
            plotOptions: {
                bar: {
                    distributed: true,
                    horizontal: true,
                    borderRadius: 1,
                    datalabels: {
                        position: 'top',
                    }
                }
            },
            //colors: ["#FF0000", "#FFA500", "#008000", "#33b2df", "#546E7A"], // this array contains different color code for each data        
            colors: [function ({ value, seriesIndex, w }) {
                //console.log(value)                    
                switch (seriesIndex) {
                    case 0:
                        return "#ff0000";

                    case 1:
                        return "#FFa500";

                    case 2:
                        return "#008000";
                    default:
                        return "#808080"

                }
            }],
            dataLabels: {
                enabled: true,
                textAnchor: 'middle',
                style: {
                    colors: "#000000",
                },
                //offsetY: 50,
            },
            xaxis: {
                categories: [">10 Days", "5-10 Days", "1-5 Days"],
            }
        },
    };

    var telemetrybarchartd2c = {
        series: [{
            data: telemetrymetricsdata.map(function (metrics) {
                if (metrics.Direction === "Inbound") {
                    return metrics.EventCount
                }
                else {
                    return 0
                }
            })//[1,2,3]//
        }],
        options: {
            chart: {
                type: 'bar',
                height: 150,
                width: 300
            },
            legend: {
                show: false
            },
            plotOptions: {
                bar: {
                    distributed: true,
                    horizontal: false,
                    borderRadius: 1,
                    datalabels: {
                        enabled: false
                    }
                }
            },
            colors: ["#000080"],
            //colors: ["#FF0000", "#FFA500", "#008000", "#33b2df", "#546E7A"], // this array contains different color code for each data        
            dataLabels: {
                enabled: false,
            },
            xaxis: {
                categories: telemetrymetricsdata.map(function (metrics) {
                    if (metrics.Direction === "Inbound") {
                        return metrics.EventDescription;
                    }
                    else {
                        return "NA"
                    }
                }),
            }
        },
    };

    var telemetrybarchartc2d = {
        series: [{
            data: telemetrymetricsdataC2D.map(function (metrics) {
                if (metrics.Direction === "Outbound") {
                    return metrics.EventCount;
                }
                else {
                    return 0
                }
            })//[1,2,3]//
        }],
        options: {
            chart: {
                type: 'bar',
                height: 150,
                width: 300
            },
            legend: {
                show: false
            },
            plotOptions: {
                bar: {
                    distributed: true,
                    horizontal: false,
                    borderRadius: 1,
                    datalabels: {
                        enabled: false
                    }
                }
            },
            colors: ["#008000"],//, "#FFA500", "#008000", "#33b2df", "#546E7A"], // this array contains different color code for each data        
            dataLabels: {
                enabled: false,
            },
            xaxis: {
                categories: telemetrymetricsdataC2D.map(function (metrics) {
                    if (metrics.Direction === "Outbound") {
                        return metrics.EventDescription;
                    }
                    else {
                        return "NA"
                    }
                }),
            }
        },
    };

    var fotabarchart = {
        series: [{
            data: fotametricsdata.map(function (metrics) { return metrics.VehicleCount; })//[1,2,3]//
        }],
        options: {
            chart: {
                type: 'bar',
                height: 150,
                width: 300
            },
            plotOptions: {
                bar: {
                    distributed: true,
                    horizontal: true,
                    borderRadius: 1,
                    datalabels: {
                        position: 'top',
                    }
                }
            },
            colors: ["#808080"],
            //colors: ["#FF0000", "#00FFFF", "#008000","#33b2df","#546E7A"], // this array contains different color code for each data        
            legend: {
                show: false
            },
            dataLabels: {
                enabled: true,
                textAnchor: 'middle',
                style: {
                    colors: ["#000000"],
                },
                //offsetY: 50,
            },
            xaxis: {
                categories: fotametricsdata.map(function (metrics) { return metrics.FOTAVersion; }),//[1,2,3]//,
            }
        },
    };

    var synstatusbarchart = {
        series: [{
            data: syncmetricsdata.map(function (metrics) { return metrics.VehicleCount; })//[1,2,3]//
        }],
        options: {
            chart: {
                type: 'bar',
                height: 150,
                width: 300
            },
            plotOptions: {
                bar: {
                    distributed: true,
                    horizontal: true,
                    borderRadius: 1,
                    datalabels: {
                        position: 'top',
                    }
                }
            },
            //colors: ["#008000", "#FF0000", "#808080", "#ffa500"], // this array contains different color code for each data        
            // colors: [
            //     function ({ value, seriesIndex, dataPointIndex, w }) {
            //         //console.log(value,seriesIndex,dataPointIndex,w)                    
            //         //console.log(w.config.series[0].data[dataPointIndex])
            //         if (w?.globals?.labels[seriesIndex] == "In Sync")
            //             return "#008000";
            //         else if (w?.globals?.labels[seriesIndex] == "Out Of Sync")
            //             return "##FFa500";
            //         else if (w?.globals?.labels[seriesIndex] == "Failed")
            //             return "#FF0000";
            //         else
            //             return "#808080";                    
            //     }
            // ],
            legend: {
                show: false
            },
            dataLabels: {
                enabled: true,
                textAnchor: 'middle',
                style: {
                    colors: "#000000",
                },
                //offsetY: 50,
            },
            xaxis: {
                categories: syncmetricsdata.map(function (metrics) { return metrics.SyncStatus; }),//[1,2,3]//,
            }
        },
    };

    var modelseriesbarchart = {
        series: [{
            data:
                //    []
                //["421","3","1193","1","4","5","6","562"]
                modelseriesmetricsdata?.map(data => data?.recordcount)//[1,2,3]//
        }],
        options: {
            chart: {
                type: 'bar',
                height: 150,
                width: 300,
                // zoom: {
                //     enabled: true,
                //     type: 'x',
                //     autoScaleY: true,
                // },
                toolbar: {
                    show: true,
                    tools: {
                        download: true,
                        selection: true,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: true,
                        reset: true | '<img src="/static/icons/reset.png" width="20">',
                        customIcons: []
                    },
                },
            },
            plotOptions: {
                bar: {
                    distributed: true,
                    //horizontal: true,
                    borderRadius: 1,
                    datalabels: {
                        position: 'top',
                    }
                }
            },
            //colors: ["#008000", "#FF0000", "#808080", "#ffa500"], // this array contains different color code for each data        
            // colors: [
            //     function ({ value, seriesIndex, w }) {
            //         //console.log(value)                    
            //         switch (seriesIndex) {
            //             case 0:
            //                 return "#008000";

            //             case 1:
            //                 return "#FF0000";

            //             case 2:
            //                 return "#808080";

            //             case 3:
            //                 return "#FFa500";
            //             default:
            //                 return "#808080"

            //         }
            //     }
            // ],
            legend: {
                show: true,
                position: 'right'
            },
            dataLabels: {
                enabled: true,
                // textAnchor: 'middle',
                // style: {
                //     colors: "#000000",
                // },
                // //offsetY: 50,
            },
            xaxis: {
                categories: modelseriesmetricsdata?.map(data => data?.Modelseries)
            }
        },
    };



    return (
        <>
            {/* {
                exportcompleted ?
                    
                    <Spinner animation="border" >
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    :
                    <></>
                    
            } */}
            <Row className="m-1">
                <Col>
                    <ButtonGroup>
                        <ToggleButton
                            //className="od-font-sm od-table od-btn-smaller"
                            variant="outline-primary"
                            size="sm"
                            onClick={filterclick}
                            type="checkbox"
                            checked={showfilter}
                        >
                            Entity Picker
                        </ToggleButton>
                        {showfilter ?
                            <ToggleButton
                                //className="od-font-sm od-table od-btn-smaller"
                                variant="outline-primary"
                                size="sm"
                                onClick={clearall}
                                type="checkbox"
                                checked={false}
                            >
                                Clear All Filters
                            </ToggleButton>
                            : <></>
                        }
                    </ButtonGroup>
                    <ButtonGroup style={{ "float": "right" }}>
                        <ToggleButton
                            //className="od-font-sm od-table od-btn-smaller"
                            variant="outline-primary"
                            size="sm"
                            onClick={summaryclicked}
                            type="checkbox"
                            checked={showsummary}
                        >
                            Summary
                        </ToggleButton>
                        <ToggleButton
                            //className="od-font-sm od-table od-btn-smaller"
                            variant="outline-primary"
                            size="sm"
                            onClick={dashboardclicked}
                            type="checkbox"
                            checked={showdashboard}
                        >
                            Dashboards
                        </ToggleButton>
                        <ToggleButton
                            //className="od-font-sm od-table od-btn-smaller"
                            variant="outline-primary"
                            size="sm"
                            onClick={vehicletelemetryclicked}
                            type="checkbox"
                            checked={showvehicletelemetry}
                        >
                            Telemetry Snapshot
                        </ToggleButton>
                        <ToggleButton
                            //className="od-font-sm od-table od-btn-smaller"
                            variant="outline-primary"
                            size="sm"
                            onClick={vehicledetailsclicked}
                            type="checkbox"
                            checked={showvehicledetails}
                        >Vehicle Details</ToggleButton>
                    </ButtonGroup>
                </Col>
            </Row>
            <Row className="m-1">
                <Col>
                    {showsummary ?
                        <Row className="mt-2">
                            <Col md="2" style={{width: 220}}>
                                <Card style={{ height: 180, width:200 }}>
                                    <Card.Header className="text-center text-bold">
                                        <label style={{ "float": "left" }}>Dealer</label>
                                        <Badge style={{ "float": "right" }}>
                                            {distributiondata.map((item) => { return item.type === "enabled" ? item.dealer : null })}
                                            /
                                            {distributiondata.map((item) => { return item.type !== "enabled" ? item.dealer : null })}
                                        </Badge>
                                    </Card.Header>
                                    <Card.Body className="od-font-sm od-table p-1">
                                        <Table className='table-sm table-striped od-font-sm od-table'>
                                            <tbody>
                                                <tr key="dealerinvitesaccepted">
                                                    <td colSpan={2}>Invites Accepted</td>
                                                    <td>{usermetricsdata?.["DEALER"]?.["ACCEPTED"]}</td>
                                                </tr>
                                                <tr key="dealerinvitespending">
                                                    <td colSpan={2}>Invites Pending</td>
                                                    <td>{usermetricsdata?.["DEALER"]?.["PENDING"]}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="2" style={{width: 220}}>
                                <Card style={{ height: 180, width:200 }}>
                                    <Card.Header className="text-center text-bold">
                                        <label style={{ "float": "left" }}>Branch</label>
                                        <Badge style={{ "float": "right" }}>
                                            {distributiondata.map((item) => { return item.type === "enabled" ? item.branch : null })}
                                            /
                                            {distributiondata.map((item) => { return item.type !== "enabled" ? item.branch : null })}
                                        </Badge>
                                    </Card.Header>
                                    <Card.Body className="od-font-sm od-table p-1">
                                        <Table className='table-sm table-striped od-font-sm od-table'>
                                            <tbody>
                                                <tr key="branchinvitesaccepted">
                                                    <td colSpan={2}>Invites Accepted</td>
                                                    <td>{usermetricsdata?.["BRANCH"]?.["ACCEPTED"]}</td>
                                                </tr>
                                                <tr key="branchinvitespending">
                                                    <td colSpan={2}>Invites Pending</td>
                                                    <td>{usermetricsdata?.["BRANCH"]?.["PENDING"]}</td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                    </Card.Body>
                                </Card>

                            </Col>
                            <Col md="2" style={{width: 220}}>
                                <Card style={{ height: 180, width:200 }}>
                                    <Card.Header className="text-center text-bold">
                                        <label style={{ "float": "left" }}>Company</label>
                                        <Badge style={{ "float": "right" }}>
                                            {distributiondata.map((item) => { return item.type === "enabled" ? item.company : null })}
                                            /
                                            {distributiondata.map((item) => { return item.type !== "enabled" ? item.company : null })}
                                        </Badge>
                                    </Card.Header>
                                    <Card.Body className="od-font-sm od-table p-1">
                                        <Table className='table-sm table-striped od-font-sm od-table'>
                                            <tbody>
                                                <tr key="companyinvitesaccepted">
                                                    <td colSpan={2}>Invites Accepted</td>
                                                    <td>{usermetricsdata?.["COMPANY"]?.["ACCEPTED"]}</td>
                                                </tr>
                                                <tr key="companyinvitespending">
                                                    <td colSpan={2}>Invites Pending</td>
                                                    <td>{usermetricsdata?.["COMPANY"]?.["PENDING"]}</td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                    </Card.Body>
                                </Card>

                            </Col>
                            <Col md="2" style={{width: 220}}>
                                <Card style={{ height: 180, width:200 }}>
                                    <Card.Header className="text-center text-bold">
                                        <label style={{ "float": "left" }}>Site</label>
                                        <Badge style={{ "float": "right" }}>
                                            {distributiondata.map((item) => { return item.type === "enabled" ? item.site : null })}
                                            /
                                            {distributiondata.map((item) => { return item.type !== "enabled" ? item.site : null })}
                                        </Badge>
                                    </Card.Header>
                                    <Card.Body className="od-font-sm od-table p-1">
                                        <Table className='table-sm table-striped od-font-sm od-table'>
                                            <tbody>
                                                <tr key="siteinvitesaccepted">
                                                    <td colSpan={2}>Invites Accepted</td>
                                                    <td>{usermetricsdata?.["SITE"]?.["ACCEPTED"]}</td>
                                                </tr>
                                                <tr key="siteinvitespending">
                                                    <td colSpan={2}>Invites Pending</td>
                                                    <td>{usermetricsdata?.["SITE"]?.["PENDING"]}</td>
                                                </tr>
                                                <tr key="siteactivity">
                                                    <td colSpan={2}>
                                                        <OverlayTrigger placement="top" overlay={tooltip}>
                                                            <ToggleButton
                                                                className="od-table od-btn-smaller"
                                                                variant="outline-info"
                                                                size="sm"
                                                                onClick={siteactivityclicked}
                                                                type="checkbox"
                                                                checked={siteactivitybtn}
                                                                style={{ "width": "110px" }}
                                                            >
                                                                Site Activity (24Hrs)
                                                            </ToggleButton>
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>{mismatch_activity["SiteActivityCount"]}</td>
                                                </tr>
                                                <tr key="sitemismatch">
                                                    <td colSpan={2}>
                                                        <OverlayTrigger placement="top" overlay={tooltip}>
                                                            <ToggleButton
                                                                className="od-font-sm od-table od-btn-smaller"
                                                                variant="outline-warning"
                                                                size="sm"
                                                                onClick={sitemismatchclicked}
                                                                type="checkbox"
                                                                checked={sitemismatchbtn}
                                                                style={{ "width": "110px" }}
                                                            >
                                                                Data Mismatch
                                                            </ToggleButton>
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td><Badge bg="warning" text="dark" style={{ "fontSize": "10px" }} className="p-1">{mismatch_activity["SiteMismatchCount"]}</Badge></td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card style={{ height: 180 }}>
                                    <Card.Header className="text-center text-bold">
                                        <label style={{ "float": "left" }}>Vehicle</label>
                                        <Badge style={{ "float": "right" }}>
                                            {distributiondata.map((item) => { return item.type === "enabled" ? item.vehiclesatcustomersite : null })}
                                            /
                                            {distributiondata.map((item) => { return item.type === "enabled" ? item.vehicle : null })}
                                        </Badge>
                                    </Card.Header>
                                    <Card.Body className="text-center p-1">
                                        <Table className='table-sm table-striped od-font-sm od-table my-1'>
                                            <thead>
                                                <tr key="vehiclesummary">
                                                    <th>Production Site</th>
                                                    <th>Distribution Site</th>
                                                    <th>
                                                        <ToggleButton
                                                            className="od-font-sm od-table od-btn-smaller"
                                                            variant="outline-secondary"
                                                            size="sm"
                                                            onClick={customersites}
                                                            type="checkbox"
                                                            checked={customersitedata}
                                                        >
                                                            Customer Site
                                                        </ToggleButton>
                                                    </th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {distributiondata.map((item, id) => {
                                                    return item.type === "enabled" ?
                                                        <tr key="vehicledistribution">
                                                            <td>{item.vehiclesatproductionsite}</td>
                                                            <td>{item.vehiclesatdistributionsite}</td>
                                                            <td>{item.vehiclesatcustomersite}</td>
                                                            <td>{item.vehicle}</td>
                                                        </tr>
                                                        : <></>
                                                }
                                                )}
                                            </tbody>
                                        </Table>
                                        <Table className='table-sm table-striped od-font-sm od-table my-1'>
                                            <thead>
                                                <tr key="vehiclesitemismatch">
                                                    <th style={{ "fontSize": "10px" }}>Paired &#10;&#13;&#40;Since {todaysDate.toString()}&#41;</th>
                                                    <th style={{ "fontSize": "10px" }}>Site Mismatch</th>
                                                    <th style={{ "fontSize": "10px" }}>Site Swaps &#10;&#13;&#40;Last 24Hrs&#41;</th>
                                                    <th style={{ "fontSize": "10px" }}>Install Completed&#10;&#13;&#40;Last 24Hrs&#41;</th>
                                                    <th style={{ "fontSize": "10px" }}>Vehicle with KeyHours {'>'} 1</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr key="vehiclesitemismatchvalues">
                                                    <td>{vehiclespaireddata["RECENTLYPAIRED"]}</td>
                                                    <td><Badge bg="warning" text="dark" style={{ "fontSize": "10px" }} className="p-1">{sitemismatchdata["MismatchCount"]}</Badge></td>
                                                    <td>{siteswapdata?.[0]?.EventCount}</td>
                                                    <td>{installcompletedata?.[0]?.EventCount}</td>
                                                    <td>{vehiclekeyhourdata?.[0]?.VehicleCount}</td>
                                                </tr>
                                            </tbody>
                                        </Table>                                        
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        : <></>
                    }
                    {
                        showfilter ?
                            <Card className="mt-2">
                                <Card.Header className="text-center text-bold">
                                    <label style={{ "float": "left" }}>Entity Picker</label>
                                </Card.Header>
                                <Card.Body className="text-center  m-1 p-0">
                                    <Row >
                                        <Col>
                                            <ReactDataGrid
                                                theme="blue-light"
                                                onReady={setdealerGridRef}
                                                onSelectionChange={onSelectionChange}
                                                style={dealersearchgridStyle}
                                                defaultFilterValue={dealersearchgriddefaultFilterValue}
                                                rowStyle={rowStyle}
                                                columns={dealersearchgrid}
                                                enableColumnAutosize
                                                pagination="remote"
                                                defaultLimit={10}
                                                dataSource={dataSourceDealer}
                                                enableSelection={true}
                                                onFilterValueChange={onDealerFilterValueChange}
                                            />
                                        </Col>
                                        <Col>
                                            <ReactDataGrid
                                                theme="green-light"
                                                onReady={setbranchGridRef}
                                                onSelectionChange={onSelectionChangeBranch}
                                                style={dealersearchgridStyle}
                                                defaultFilterValue={branchsearchgriddefaultFilterValue}
                                                rowStyle={rowStyle}
                                                columns={branchsearchgrid}
                                                enableColumnAutosize
                                                pagination="remote"
                                                defaultLimit={10}
                                                dataSource={dataSourceBranch}
                                                enableSelection={true}

                                            />
                                        </Col>
                                        <Col>
                                            <ReactDataGrid
                                                theme="amber-light"
                                                onReady={setcompanyGridRef}
                                                onSelectionChange={onSelectionChangeCompany}
                                                style={dealersearchgridStyle}
                                                defaultFilterValue={companysearchgriddefaultFilterValue}
                                                rowStyle={rowStyle}
                                                columns={companysearchgrid}
                                                enableColumnAutosize
                                                pagination="remote"
                                                defaultLimit={10}
                                                dataSource={dataSourceCompany}
                                                enableSelection={true}

                                            />
                                        </Col>
                                        <Col>
                                            <ReactDataGrid
                                                theme="pink-light"
                                                onReady={setsiteGridRef}
                                                onSelectionChange={onSelectionChangeSite}
                                                style={dealersearchgridStyle}
                                                defaultFilterValue={sitesearchgriddefaultFilterValue}
                                                rowStyle={rowStyle}
                                                columns={sitesearchgrid}
                                                enableColumnAutosize
                                                pagination="remote"
                                                defaultLimit={10}
                                                dataSource={dataSourceSite}
                                                enableSelection={true}
                                            />
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            :
                            <></>
                    }
                    {showdashboard ?
                        <>
                            <Row className="mt-2">
                                <Col md="3">
                                    <Card style={{ height: 300 }}>
                                        <Card.Header className="text-center text-bold">
                                            <label style={{ "float": "left" }}>VNC-Vehicle Not Communicating (Count)</label>
                                        </Card.Header>
                                        <Card.Body>
                                            <ApexChart
                                                options={vncbarchart.options}
                                                series={vncbarchart.series}
                                                type="bar"
                                                width="100%"
                                                height="100%"
                                            />
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md="3">
                                    <Card style={{ height: 300 }}>
                                        <Card.Header className="text-center text-bold">
                                            <label style={{ "float": "left" }}>FOTA Version of Vehicles (Count)</label>
                                            {selectedvnc == "1" ?                                                
                                                <label id="msvnc" style={{ "float": "Right", "backgroundColor": "#ff0000"}}>VNC {'>'} 10</label>
                                                :selectedvnc == "2" ? 
                                                    <label id="msvnc" style={{ "float": "Right", "backgroundColor": "#FFa500"}}> 10 {'>'} VNC {'>'} 5 </label>
                                                    :selectedvnc == "3" ?
                                                        <label id="msvnc" style={{ "float": "Right", "backgroundColor": "#008000"}}>VNC {'<'} 5</label>
                                                        :<label></label>                                                
                                            }
                                        </Card.Header>
                                        <Card.Body>
                                            <ApexChart
                                                options={fotabarchart.options}
                                                series={fotabarchart.series}
                                                type="bar"
                                                width="100%"
                                                height="100%"
                                            />
                                        </Card.Body>
                                    </Card>
                                </Col>


                                <Col md="3">
                                    <Card style={{ height: 300 }}>
                                        <Card.Header className="text-center text-bold">
                                            <label style={{ "float": "left" }}>Sync Status of Vehicles (Count)</label>
                                            {selectedvnc == "1" ?                                                
                                                <label id="msvnc" style={{ "float": "Right", "backgroundColor": "#ff0000"}}>VNC {'>'} 10</label>
                                                :selectedvnc == "2" ? 
                                                    <label id="msvnc" style={{ "float": "Right", "backgroundColor": "#FFa500"}}> 10 {'>'} VNC {'>'} 5 </label>
                                                    :selectedvnc == "3" ?
                                                        <label id="msvnc" style={{ "float": "Right", "backgroundColor": "#008000"}}>VNC {'<'} 5</label>
                                                        :<label></label>                                                
                                            }
                                        </Card.Header>
                                        <Card.Body>
                                            <ApexChart
                                                options={synstatusbarchart.options}
                                                series={synstatusbarchart.series}
                                                type="bar"
                                                width="100%"
                                                height="100%"
                                            />
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col md="3">
                                    <Card style={{ height: 300 }}>
                                        <Card.Header className="text-center text-bold">
                                            <label style={{ "float": "left" }}>Count of Vehicles By ModelSeries</label>                                            
                                            {selectedvnc == "1" ?                                                
                                                <label id="msvnc" style={{ "float": "Right", "backgroundColor": "#ff0000"}}>VNC {'>'} 10</label>
                                                :selectedvnc == "2" ? 
                                                    <label id="msvnc" style={{ "float": "Right", "backgroundColor": "#FFa500"}}> 10 {'>'} VNC {'>'} 5 </label>
                                                    :selectedvnc == "3" ?
                                                        <label id="msvnc" style={{ "float": "Right", "backgroundColor": "#008000"}}>VNC {'<'} 5</label>
                                                        :<label></label>                                                
                                            }
                                        </Card.Header>
                                        <Card.Body style={{ overflow: 'auto' }}>
                                            {/* <ApexChart
                                                options={modelseriesbarchart.options}
                                                series={modelseriesbarchart.series}
                                                type="bar"
                                                width="100%"
                                                height="100%"
                                            /> */}
                                            <Table className='table-sm table-striped od-font-sm od-table my-1'>
                                                <thead>
                                                    <tr key="vehiclemodelseriescount">
                                                        <th>Model Series</th>
                                                        <th>Counts</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        modelseriesmetricsdata?.map((item) => {
                                                            return <tr>
                                                                <td>{item.Modelseries}</td>
                                                                <td>{item.recordcount}</td>
                                                            </tr>
                                                        })
                                                    }

                                                </tbody>
                                            </Table>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col md={8}>
                                    <Card style={{ height: 350 }}>
                                        <Card.Header className="text-center text-bold">
                                            <label style={{ "float": "left" }}>Device Telemetry Received (Last 24 Hrs)</label>
                                        </Card.Header>
                                        <Card.Body>
                                            <ApexChart
                                                options={telemetrybarchartd2c.options}
                                                series={telemetrybarchartd2c.series}
                                                type="bar"
                                                width="100%"
                                                height="100%"
                                            />
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card style={{ height: 350 }}>
                                        <Card.Header className="text-center text-bold">
                                            <label style={{ "float": "left" }}>Configurations & Commands Sent  (Last 24 Hrs)</label>
                                        </Card.Header>
                                        <Card.Body>
                                            <ApexChart
                                                options={telemetrybarchartc2d.options}
                                                series={telemetrybarchartc2d.series}
                                                type="bar"
                                                width="100%"
                                                height="100%"
                                            />
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                        : <></>
                    }
                    {
                        showvehicletelemetry ?
                            <Row className="mt-2">
                                <Col>
                                    <Card style={{ height: 110 }}>
                                        <Card.Header className="text-center text-bold">
                                            <label style={{ "float": "left" }}>D2C Metrics (Last 24 Hrs)</label>
                                        </Card.Header>
                                        <Card.Body className="text-center  m-1 p-0">
                                            <Table className='table-sm table-striped od-font-sm od-table'>
                                                <thead>
                                                    <tr key="vehicled2ctelemetry">
                                                        <th className='small'>Low Impacts</th>
                                                        <th className='small'>Medium Impacts</th>
                                                        <th className='small'>High Impacts</th>
                                                        <th className='small'>Critical Impacts</th>
                                                        <th className='small'>Key Hours</th>
                                                        <th className='small'>Travel Hours</th>
                                                        <th className='small'>Activity Hours</th>
                                                        <th className='small'>Operator Presence Hours</th>
                                                        <th className='small'>Errors</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        d2cmetricsdata !== "" && d2cmetricsdata !== undefined && d2cmetricsdata.length > 0 && d2cmetricsdata?.map((item) => {
                                                            return <tr key="d2cmetricsdata">
                                                                <td className='small'>{item.low != null ? item.low : 0}</td>
                                                                <td className='small'>{item.medium != null ? item.medium : 0}</td>
                                                                <td className='small'>{item.high != null ? item.high : 0}</td>
                                                                <td className='small'>{item.critical != null ? item.critical : 0}</td>
                                                                <td className='small'>{item.keyhours != null ? item.keyhours : 0}</td>
                                                                <td className='small'>{item.travelhours != null ? item.travelhours : 0}</td>
                                                                <td className='small'>{item.activityhours != null ? item.activityhours : 0}</td>
                                                                <td className='small'>{item.operatorpresencehours != null ? item.operatorpresencehours : 0}</td>
                                                                <td className='small'>{item.errorcount != null ? item.errorcount : 0}</td>
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            :
                            <></>
                    }
                    {
                        showvehicledetails ?
                            <Row className="mt-2">
                                <Col>
                                    <Card>
                                        <Card.Header className="text-center text-bold">
                                            <label style={{ "float": "left" }}>Vehicle Details</label>
                                            <Button style={{ "float": "right" }} size="sm" onClick={downloadBlob} disabled={exportcompleted}>Export</Button>
                                        </Card.Header>
                                        <Card.Body className="text-center  m-1 p-0">
                                            {props.pca?.getActiveAccount()?.username !== undefined ?
                                                <ReactDataGrid
                                                    //defaultSortInfo={defaultSortInfo}
                                                    //idProperty="_id"
                                                    //className="mb-4"
                                                    style={gridStyle}
                                                    rowStyle={rowStyle}
                                                    columns={gridcols}
                                                    //selected={props.selected}
                                                    dataSource={dataSource}
                                                    //onRowClick={handleGridRowClick}
                                                    defaultFilterValue={defaultFilterValue}
                                                    enableColumnAutosize
                                                    pagination="remote"
                                                    //checkboxColumn={props.checkboxreq}
                                                    //multiSelect={props.multiSelect}
                                                    //editable={props.editable}
                                                    //onSelectionChange={(e) => onSelectionChange(e)}
                                                    defaultLimit={10}
                                                    //defaultSkip={0}
                                                    //pageSizes={[25]}
                                                    onFilterValueChange={setFilterValue}
                                                //maxWidth ={600}
                                                /> : <></>
                                            }
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            :
                            <></>
                    }

                </Col >
            </Row >
        </>
    );
}

export default DistributionSnapshot;
