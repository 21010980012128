import React from "react";
import { useState, useEffect } from "react";
import { Row, Col, Card, Table,  Badge } from 'react-bootstrap';
import "react-datetime/css/react-datetime.css";
import { getIWGMetricsData, getIWGFailureLogs, getIWGMetricsDataEU, getIWGFailureLogsEU} from "../service/getdata";
import ReactDataGrid from '@inovua/reactdatagrid-community'

const gridStyle = { minHeight: 200, fontSize: 10 }
const rowStyle = ({ data }) => {
  return {
    fontSize: 12,
    color: 'red'
  }
}

const redRowStyle =  { color : 'red', fontWeight : 'bold'}
const normalRowStyle =  { color : 'black'}

const gridcols = [
  { header: "iWG Extract Id", name: 'iWarehouseExtractId', type: 'string', minWidth: 30, textAlign: 'center' },
  { header: "Process", name: 'Process', type: 'string', minWidth: 220, headerAlign: 'center' },
  { header: "StartTime", name: 'StartTime', type: 'datetime', minWidth: 120, headerAlign: 'center' },
  { header: "EndTime", name: 'EndTime', type: 'datetime', minWidth: 120, textAlign: 'center' },
  { header: "Status", name: 'Status', type: 'string', minWidth: 100, textAlign: 'center' },
  { header: "Status Description", name: 'StatusDesc', type: 'string', minWidth: 300, textAlign: 'center' },
  { header: "Duration", name: 'Duration', type: 'string', minWidth: 100, textAlign: 'center' },
  { header: "Records Count", name: 'RecordCount', type: 'string', minWidth: 100, textAlign: 'center' },
  { header: "Mark Records Extracted", name: 'MarkRecordsExtracted', type: 'string', minWidth: 100, textAlign: 'center' },    
]

const defaultSortInfo = { name: 'StartTime', dir: -1, type: 'datetime' }

function IWGatewayInterfaceHealth() {   
    const [iwgmetricsdata, setiwgmetricsdata] = useState([]);
    const [iwgfailurelogs, setiwgfailurelogs] = useState([]);
    const [selectedmetricstable, setmetricstable] = useState("Metrics-US");
    const [iwgmetricsdataEU, setiwgmetricsdataEU] = useState([]);
    const [iwgfailurelogsEU, setiwgfailurelogsEU] = useState([]);


    const findlatesttimestamp = () => {
      let dates = iwgmetricsdata.map((b)=>new Date(b.MetricsGeneratedDateTime))
      return new Date(Math.max.apply(null, dates)).toLocaleString();    
    }

    useEffect(() => {
          getIWGMetricsData().then((d) => {
            setiwgmetricsdata(JSON.parse(JSON.stringify(d?.data)))
            console.log('Test')
            // console.log(iwgmetricsdata.length)
            // console.log(iwgmetricsdata)
          })
          getIWGMetricsDataEU().then((d) => {
            setiwgmetricsdataEU(JSON.parse(JSON.stringify(d?.data)))
            console.log('TestEU')
            // console.log(iwgmetricsdataEU.length)
            // console.log(iwgmetricsdataEU)
          })
    }, [selectedmetricstable === "Metrics-US" ? iwgmetricsdata.length : iwgmetricsdataEU.length])
    
    useEffect(() => {
          getIWGFailureLogs().then((d) => {
          setiwgfailurelogs(JSON.parse(JSON.stringify(d?.data)))
          console.log(iwgfailurelogsEU)
          })
          getIWGFailureLogsEU().then((d) => {
            setiwgfailurelogsEU(JSON.parse(JSON.stringify(d?.data)))
          console.log(iwgfailurelogsEU)
          })
        // }   
    }, [selectedmetricstable === "Metrics-US" ? iwgfailurelogs.length : iwgfailurelogsEU.length])

    const handleRadioChange = (e) => {
      setmetricstable(e.target.value);
    }

    const getiwgmetricscolumns= (item) =>
    {
      return <tr>
            <td className='small text-center'>{item.CurrentDate}</td>
            <td className='small text-center' style={item.ExtractCallsAvgDuration_RedFlag == 1 ? redRowStyle : normalRowStyle}>{item.ExtractCallsAvgDuration.toLocaleString()}</td>
            <td className='small text-center' style={item.TotalEventsCount_CurrentDate_RedFlag == 1 ? redRowStyle : normalRowStyle}>{item.TotalEventsCount_CurrentDate.toLocaleString()}</td>
            <td className='small text-center' style={item.TotalEventsCount_Delta_RedFlag == 1 ? redRowStyle : normalRowStyle}>{item.TotalEventsCount_Delta.toLocaleString()}</td>
            <td className='small text-center' style={item.EventsCount_WithNoOperator_RedFlag == 1 ? redRowStyle : normalRowStyle}>{item.EventsCount_WithNoOperator.toLocaleString()}</td>
            </tr>
    }

    const getMetadataMismatchColumns = (item) => {
        return <tr>                        
        <td className='small text-center' style={item.SiteMisMatchCount > 0 ? redRowStyle : normalRowStyle}>{item.SiteMisMatchCount.toLocaleString()}</td>
        <td className='small text-center' style={item.VehicleMisMatchCount > 0 ? redRowStyle : normalRowStyle}>{item.VehicleMisMatchCount.toLocaleString()}</td>        
        <td className='small text-center' style={item.OperatorMisMatchCount > 0 ? redRowStyle : normalRowStyle}>{item.OperatorMisMatchCount.toLocaleString()}</td>                            
        </tr>
    }

    return (
        <>     
           <Row className="mt-2" style = {{marginTop : '0.1 rem'}}>
            <Col  md={7}>
            <div>
              <label style ={{margin : '20px'}}>
                    <input type="radio" value = "Metrics-US" checked={selectedmetricstable === "Metrics-US"} style = {{fontSize : '14px'}}
                    onChange={handleRadioChange}/>
                      <strong style = {{marginLeft : '5px'}}>
                        Metrics - US
                      </strong>
                  </label>

                  <label style ={{margin : '20px'}}>
                    <input type="radio" value = "Metrics-EU" checked={selectedmetricstable === "Metrics-EU"} style = {{fontSize : '14px'}}
                     onChange={handleRadioChange}/>
                      <strong style = {{marginLeft : '5px'}}>
                        Metrics - EU
                      </strong>
                  </label>
              </div>
              <Card style={{ height: 160 }}>
                <Card.Header >
                {selectedmetricstable === "Metrics-US" ? 
                  <strong>
                          iWG Data Metrics - US
                  </strong> : 
                  <strong>
                          iWG Data Metrics - EU
                  </strong>
                  }
                  {                
                        ((new Date().getTime() - new Date(findlatesttimestamp()).getTime()) > 300000)
                        ?
                        <Badge pill style={{ float: 'right' }} bg="warning">
                        {
                            findlatesttimestamp()
                        }
                        </Badge>
                        :
                        <Badge pill style={{ float: 'right' }} bg="success">
                        {
                            findlatesttimestamp()
                        }
                        </Badge>
                    }                
                </Card.Header>
                <Card.Body style={{ overflow: 'auto' }}>              
                  <Table className='table-sm table-striped od-font-sm'>
                    <thead>
                      <tr>
                        <th className='small text-center'>Date</th>
                        <th className='small text-center'>Extract Call Avg Duration</th>
                        <th className='small text-center'>Total Events Logged</th>
                        <th className='small text-center'>Total Events Logged Delta</th>
                        <th className='small text-center'>Events with No Operator Data</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                        selectedmetricstable === "Metrics-US" ? iwgmetricsdata.map((item, id) =>getiwgmetricscolumns(item)):
                        iwgmetricsdataEU.map((item, id)=>getiwgmetricscolumns(item))}  
                    </tbody>
                  </Table>
                                    
                </Card.Body>
              </Card>
            </Col>
            <Col  md={5}>
              <Card style={{ height: 160, marginTop: '65px' }}>
                <Card.Header >
                {selectedmetricstable === "Metrics-US" ? 
                  <strong>
                          Metadata Mismatch Count - US
                  </strong> : 
                  <strong>
                          Metadata Mismatch Count - EU
                  </strong>
                  }
                  {                
                        ((new Date().getTime() - new Date(findlatesttimestamp()).getTime()) > 300000)
                        ?
                        <Badge pill style={{ float: 'right' }} bg="warning">
                        {
                            findlatesttimestamp()
                        }
                        </Badge>
                        :
                        <Badge pill style={{ float: 'right' }} bg="success">
                        {
                            findlatesttimestamp()
                        }
                        </Badge>
                    }                  
                </Card.Header>
                <Card.Body style={{ overflow: 'auto' }}>              
                  <Table className='table-sm table-striped od-font-sm'>
                    <thead>
                      <tr>
                        <th className='small text-center'>Site Records Mismatch Count</th>
                        <th className='small text-center'>Vehicle Records Mismatch Count</th>
                        <th className='small text-center'>Operator Records Mismatch Count</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                        selectedmetricstable === "Metrics-US" ? iwgmetricsdata.map((item, id) => getMetadataMismatchColumns(item))
                        : iwgmetricsdataEU.map((item, id) => getMetadataMismatchColumns(item))                       
                    }  
                    </tbody>
                  </Table>
                  
                </Card.Body>
              </Card>
            </Col>
    
          </Row>
          <Row>
            <Col className="m-1">
              <Card style={{ height: 290 }}>
                <Card.Header >
                {selectedmetricstable === "Metrics-US" ? 
                  <strong>
                          iWG Extract Failure Logs - US
                  </strong> : 
                  <strong>
                          iWG Extract Failure Logs - EU
                  </strong>
                  }
                  {                
                        ((new Date().getTime() - new Date(findlatesttimestamp()).getTime()) > 300000)
                        ?
                        <Badge pill style={{ float: 'right' }} bg="warning">
                        {
                            findlatesttimestamp()
                        }
                        </Badge>
                        :
                        <Badge pill style={{ float: 'right' }} bg="success">
                        {
                            findlatesttimestamp()
                        }
                        </Badge>
                    }                 
                </Card.Header>
                <Card.Body style={{ overflow: 'auto' }}>              
                <ReactDataGrid
                defaultSortInfo={defaultSortInfo}
                style={gridStyle}
                rowStyle={rowStyle}
                columns={gridcols}
                dataSource={selectedmetricstable === "Metrics-US" ? iwgfailurelogs : iwgfailurelogsEU}
                enableColumnAutosize
                pagination="local"
                defaultLimit={10}
                pageSizes={[25, 50, 100, 500]}
              />
                  
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      );
    }

    export default IWGatewayInterfaceHealth;